import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SetTitleService {
  public title$ = new Subject();

  /**
   * Constructor function
   * @param title - a Title object
   * @param authServ - a AuthService object
   */
  constructor(private title: Title, private authServ: AuthService) {
    this.title = title;
    this.authServ = authServ;
  }

  /**
   * Set a new title
   * @param titleES - The Spanish title
   * @param titleEN - The English title
   */
  setTitle(titleES: string, titleEN: string): void {
    const currentLanguage: string = this.authServ.getUserParam('lang');

    if (currentLanguage === 'ES') {
      this.title.setTitle(titleES);
    } else if (currentLanguage === 'EN') {
      this.title.setTitle(titleEN);
    } else {
      this.title.setTitle('International SOS');
    }
  }
}
