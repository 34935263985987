// Needed imports
import { Table, TableStatus } from '../../../shared/components/no-category/table/models/table';
import { CalendarConfig } from '../../../core/models/local/calendarConfig.model';
import { Numbers } from '../../../utils/constants';

// DropDownOption interface
export interface DropDownOption {
  label: string;
  value: string;
}

// RequestData interface model
export interface RequestData {
  identificador: string;
  tipoCertificado?: string;
  tipoEncargo?: string;
  estado?: string;
  nombre?: string;
  apellido1?: string;
  apellido2?: string;
  numeroPoliza?: string;
  numeroTelefono?: string;
  fechaDesde?: string;
  fechaHasta?: string;
  orderID?: string;
  caseID?: string;
  observe?: 'body';
  resportProgress?: boolean;
}

// GD orders
export const ORDER_FINDER_TABLE_CONFIG_GD: Table = {
  // Indicate the colum name and its properties
  cols: [
    {
      // NºGD/ NºFDC
      field: 'idExpediente',
      showFilter: false,
      header: 'FINDER-PAGE.TABLE.HEADERS.ID',
      class: 'column--blue-pointer',
      launchSelectRowEvent: 'detail',
      colType: 'button'
    },
    {
      // Nº Orden
      field: 'idEncargo',
      showFilter: false,
      header: 'FINDER-PAGE.TABLE.HEADERS.ORDER-ID'
    },
    {
      // tipo certificado
      field: 'tipoCertificado',
      showFilter: false,
      header: 'FINDER-PAGE.TABLE.HEADERS.CERT-TYPE'
    },

    {
      // Persona certificacion
      field: 'personaCertificacion',
      header: 'TABLE.HEADERS.CERT-PERSON',
      showFilter: false
    },
    {
      // Estado
      field: 'estado',
      showFilter: false,
      header: 'FINDER-PAGE.TABLE.HEADERS.STATE'
    },
    {
      // Nombre del fallecido
      field: 'nombreAseguradoCompleto',
      showFilter: false,
      header: 'FINDER-PAGE.TABLE.HEADERS.DECEASED-NAME'
    }
  ],
  // init data
  dataSource: []
};

// FDC Orders
export const ORDER_FINDER_TABLE_CONFIG_FDC: Table = {
  // Indicate the colum name and its properties
  cols: [
    {
      // NºGD / Nº FDC
      field: 'idExpediente',
      showFilter: false,
      header: 'FINDER-PAGE.TABLE.HEADERS.ID',
      class: 'column--blue-pointer',
      launchSelectRowEvent: 'detail',
      colType: 'button'
    },
    {
      // Nº Orden
      field: 'idEncargo',
      showFilter: false,
      header: 'FINDER-PAGE.TABLE.HEADERS.ORDER-ID'
    },
    {
      // Tipo de certificado
      field: 'tipoCertificado',
      showFilter: false,
      header: 'FINDER-PAGE.TABLE.HEADERS.CERT-TYPE'
    },
    {
      // Estado
      field: 'estado',
      showFilter: false,
      header: 'FINDER-PAGE.TABLE.HEADERS.STATE'
    },
    {
      // Nombre del titular
      field: 'nombreAseguradoCompleto',
      showFilter: false,
      header: 'FINDER-PAGE.TABLE.HEADERS.HEADLINE-NAME'
    }
  ],
  // init data
  dataSource: []
};

export const ORDER_FINDER_TABLE_STATUS: TableStatus = {
  rowsToDisplay: Numbers.number20,
  firstElement: 0,
  rowsSelected: []
};

// Calendar configuration
export const ORDER_FINDER_CALENDAR_CONFIG_ES: CalendarConfig = {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
  monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  today: 'Hoy',
  clear: 'Borrar'
};
