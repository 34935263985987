import { AbstractControl } from '@angular/forms';
import { Numbers } from '../../utils/constants';

/**
 * @description Check if the nif is correct
 * @param control nif form field
 * @returns is correct or not
 */
export const validNIFValidator = (control: AbstractControl): { [key: string]: boolean } => {
  const nif: any = control.value;
  const nifRegex: RegExp = new RegExp(/^\d{8}[a-zA-Z]$/);
  let invalidNIF = false;

  // ** Check if NIF has the correct format
  if (!nifRegex.test(nif)) {
    invalidNIF = true;
  }

  const allNumbersRegex: RegExp = new RegExp(/\d+/);
  const letterRegex: RegExp = new RegExp(/[a-zA-Z]/);

  const letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E', 'T'];

  // ** Get the "REMAINDER" of the NUM and the current Letter
  const nifNumber = (nif.match(allNumbersRegex) || [])[0] || '';
  const remainder = Number(nifNumber) % Numbers.number23;

  const nifLetter = (nif.match(letterRegex) || [])[0] || '';

  const isValidLetter = letters[remainder].toLowerCase() === nifLetter.toLowerCase();

  let isValidNif: { isInvalidNIF: boolean } = { isInvalidNIF: true };
  if (isValidLetter && !invalidNIF) {
    isValidNif = null;
  }
  return isValidNif;
};

/**
 * @description Check if the cif is correct
 * @param control cif form field
 * @returns is correct or not
 */
export const validCIFValidator = (control: AbstractControl): { [key: string]: boolean } => {
  const cif: any = control.value;
  const cifRegex = new RegExp(/^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/);

  let isInvalidCIF = false;

  // check if CIF has the correct format
  if (!cifRegex.test(cif)) {
    isInvalidCIF = true;
  }

  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];

  // get special digits from CIF
  // const cifSociety = (cif.match(/^([ABCDEFGHJKLMNPQRSUVW])/) || [])[0];
  const cifNumbers: any = (cif.match(/\d{7}/) || [])[0];
  const cifControlDig: any = (cif.match(/([0-9A-J])$/) || [])[0];

  const sumEvenAndOdds: { even: number; odd: number } = cifNumbers.split('').reduce(
    (acc: { even: number; odd: number }, curr: string, i: number) => {
      if (i % Numbers.number2 === 0) {
        // impares
        const num = String(Number(curr) * Numbers.number2);
        if (num.length > Numbers.number1) {
          acc.odd += Number(num.charAt(0)) + Number(num.charAt(1));
        } else {
          acc.odd += Number(num.charAt(0));
        }
      } else {
        // pares
        acc.even += Number(curr);
      }
      return acc;
    },
    { even: Numbers.number0, odd: Numbers.number0 }
  );

  const sumDigits = String(sumEvenAndOdds.even + sumEvenAndOdds.odd);
  let resultControlDigit = Numbers.number10 - Number(sumDigits.charAt(1));
  if (Number(sumDigits) % Numbers.number10 === Numbers.number0) {
    resultControlDigit = Numbers.number0;
  }

  let invalidCIF: { isInvalidCIF: boolean } = { isInvalidCIF: true };

  if ((isNaN(cifControlDig) && letters[resultControlDigit] === (cifControlDig || isInvalidCIF)) || (!isNaN(cifControlDig) && (Number(cifControlDig) === resultControlDigit || isInvalidCIF))) {
    invalidCIF = null;
  }
  return invalidCIF;
};

/**
 * @description Check if the nie is correct
 * @param control nie form field
 * @returns is correct or not
 */
export const validNIEValidator = (control: AbstractControl): { [key: string]: boolean } => {
  const nieRegex: RegExp = new RegExp(/^([XYZ]{1})(\d{7,8})([A-Z]{1})$/);

  let invalidNIF = false;

  // check if CIF has the correct format
  if (!nieRegex.test(control.value)) {
    invalidNIF = true;
  }

  // transform NIE into equivalent NIF
  const transformNIEintoNIF: (nie: string) => string = (nie: string): string => {
    const niePrefix: string = nie.charAt(0);
    const convertedValues: { [key: string]: number } = { X: Numbers.number0, Y: Numbers.number1, Z: Numbers.number2 };

    return convertedValues[niePrefix] + nie.substring(1);
  };

  const nif: string = transformNIEintoNIF(control.value);

  const allNumbersRegex: RegExp = new RegExp(/\d+/);
  const letterRegex: RegExp = new RegExp(/[a-zA-Z]/);

  const letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E', 'T'];

  // ** Get the "REMAINDER" of the NUM and the current Letter
  const nifNumber = (nif.match(allNumbersRegex) || [])[0] || '';
  const remainder = Number(nifNumber) % Numbers.number23;

  const nifLetter = (nif.match(letterRegex) || [])[0] || '';

  const isValidLetter = letters[remainder].toLowerCase() === nifLetter.toLowerCase();

  let isValidNie: { isInvalidNIF: boolean } = { isInvalidNIF: true };
  if (isValidLetter && !invalidNIF) {
    isValidNie = null;
  }
  return isValidNie;
};
